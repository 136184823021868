.pollWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 2rem;
}

.pollOptions {
    font-size: 14px;
    font-weight: bold;
    background-color: white;
    padding: 8px 13px;
    border-radius: 25px;
    border: 1px solid #00000030;
    cursor: pointer;
    color: #121212;
}

.pollOptions:hover {
    font-size: 14px;
    font-weight: bold;
    background-color: #f1efff;
    color: #5a4bda;
    padding: 8px 13px;
    border-radius: 25px;
    border: 1px solid #5a4bda;
    cursor: pointer;
}

.pollOptionsActive {
    font-size: 14px;
    font-weight: bold;
    background-color: #5a4bda;
    color: white;
    padding: 8px 13px;
    border-radius: 25px;
    border: 1px solid #00000050;
    cursor: pointer;
}

.timeRemainingAndSubmit {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    margin: 0 2rem;
}

.timeRemainingText {
  color: #5a4bda;
  align-items: center;
}

.timeText {
  color: #ce513b;
  align-self: center;
}

.submitButton {
  background-color: #5a4bda;
  color: white;
  padding: 5px 0;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 25px;
  border: 1px solid #5a4bda;
  cursor: pointer;
  width: 6rem;
}

.submittedButton {
  background-color: #ffffff;
  color: #5a4bda;
  border: 1px solid #5a4bda;
  padding: 5px 0;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 25px;
  cursor: pointer;
  width: 6rem;
}
.submitButtonInactive {
  background-color: #ffffff;
  color: #5a4bda40;
  border: 1px solid #5a4bda40;
  padding: 5px 0;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 25px;
  /* cursor: pointer; */
  width: 6rem;
}

.submitButtonWrapper {
  display: flex;
  justify-content: center;
  align-self: center;
}

.submitButtonInactiveWrapper {
  display: flex;
  justify-content: center;
  align-self: center;
}

.submitButton:hover {
  background-color: #473cad;
}

.integerInputBox {
  width: 8rem;
  margin-top: 2.5rem;
  height: 0.8rem;
  border-radius: 25px;
  border: 2px solid #5a4bda50;
  padding: 0.5rem 1rem;
  font-size: 14px;
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.noPollImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3.5rem;
}

.noPollImage {
  height: 80px;
  width: 80px;
  object-fit: contain;
}

.leaderboardWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 0 1.5rem;
}

.leaderboardTopperWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  background-color: #f1efff;
  border-radius: 10px;
  border: 1px solid #5a4bda;
  margin: 0 1rem;
}

.leaderboardHeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
}

.leaderboardHeaderText {
  font-size: 12px;
  color: #454545;
  text-align: center;
}

.leaderboardText {
  font-size: 13px;
  font-weight: bold;
  color: #757575;
  text-align: center;
}

.leaderboardTopperText {
  font-size: 14px;
  font-weight: bold;
  color: #5a4bda;
  text-align: center;
}