.sideMenuWrapper {
    height: 100%;
    width: 100%;
    background-color: white;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    overflow: hidden;
}

.sideMenuTopComponent {
    background-color: #fafafa50;
}

.sideMenuBottomComponent {
    height: 100%;
    width: 100%;
}

.pollDoubt3dNavbar {
    display: flex;
    justify-content: space-around; 
    background-color: #5a4bda;
    border-radius: 0 0 15px 15px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.pollDoubt3dNavOptions {
    /* background-color: #f1efff; */
    color: #ffffff;
    font-weight: bold;
    margin: 1rem 0;
    padding: 0.3rem 1rem;
    border-radius: 50px;
    cursor: pointer;
    border: 1px solid #5a4bda;
}
.pollDoubt3dNavOptions:hover {
  border: 1px solid #ffffff;
    /* background-color: #f1efff; */
    /* color: #5a4bda; */
    font-weight: bold;
    margin: 1rem 0;
    padding: 0.3rem 1rem;
    border-radius: 50px;
    cursor: pointer;
}

.pollDoubt3dNavOptionsActive {
    background-color: #f1efff;
    color: #5a4bda;
    font-weight: bold;
    margin: 1rem 0;
    padding: 0.3rem 1rem;
    border-radius: 50px;
    cursor: pointer;
    border: 1px solid #ffffff;

}

.threeDWrapper {
    height: 100%;
    max-height: 20em;
    display: block;
}