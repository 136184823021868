.chatListWrapper {
    height: 100%;
    width: 100%;
    background-color: white;
    display: grid;
    grid-template-rows: 1fr 5fr 1fr;
}

.chatListTopBar {
    height: fit-content;
    width: 100%;
}

.chatListTitle {
    margin: 0px;
    width: 100%;
    font-weight: 700;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 5px;
    font-size: 120%;
    color: #1a1a1a
}

.chatListSubTitle {
    margin: 0px;
    width: 100%;
    font-weight: 500;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 5px;
    font-size: 100%;
    color: #667085;
}

.chatListMiddleBar {
    background-color: #F2F4F7;
    height: 100%;
    width: 100%;
    display: grid;
}

.chatListBottomBar {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    gap: 10px;
    border: 1px solid rgba(0,0,0,0.1);
    /* box-shadow: 1px 10px 15px rgba(0,0,0,0.5); */
    border-radius: 0px 0px 10px 10px;
}

.chatListBottomBarIcon {
    height: 100%;
    max-height: 23px;
}

.chatInputBox {
    width: 100%;
    border: none;
}

.chatInputBox:focus {
    outline: none;
}

.sendMessageIconChatList {
    max-height: 25px;
}