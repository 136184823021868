.videoPlayerWrapper {
    height: 100%;
    width: 100%;
    background-color: black;
    display: grid;
    grid-template-rows: 1fr 6fr 1fr;
    overflow: hidden;
}

.videoPlayerTopBar {
    background-color: white;
}

.videoPlayerMiddleBar {
    background-color: white;
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
}

.videoPlayerBottomBar {
    background-color: white;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
}

.videoPlayer {
    height: 100vh;
    width: 70vw;
}

#player_id {
    --op-accent-color: #5a4bda;
    background-color: black;
    background-image: url("https://img.pikbest.com/png-images/20190918/cartoon-snail-loading-loading-gif-animation_2734139.png!bw700");;
    background-repeat: no-repeat;
    background-position: center;
}

.videoPlayerBottomBar .left {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1em;
}

.videoPlayerBottomBar .middle {
    display: grid;
    height: 100%;
    width: fit-content;
    place-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
}

.liveIconVideoPlayer {
    height: 100%;
    max-height: 50px;
    width: auto;
}

.videoPlayerMiddleBottomIcons {
    height: 100%;
    max-height: 25px;
    border: 2px solid #F2F4F7;
    border-radius: 50%;
    display: grid;
    place-items: center;
    padding: 10px;
}